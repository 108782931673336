import { Controller, FormProvider, useForm } from "react-hook-form";
import ButtonLoader from "shared/ui/ButtonLoader";
import {
  Box,
  Button,
  Grid,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  InfoOutlined,
  CancelOutlined,
  RefreshOutlined,
} from "@mui/icons-material";
import { read, utils } from "xlsx";
import toast from "react-hot-toast";
import { useCallback, useEffect, useRef, useState } from "react";
import BasicDateTimePicker from "shared/ui/DateTimePicker";
import TextArea from "antd/es/input/TextArea";
import UploadFile from "@mui/icons-material/UploadFile";
import { useAsyncDispatch } from "shared/helpers/hooks/useAsyncDispatch";
import {
  CancelOneTimeTrigger,
  CreateOneTimeTrigger,
  GetOneTimeSurvey,
  RescheduleOneTimeTrigger,
} from "store/slicers/customers";
import { ERequestStatus } from "store/enums/index.enum";
import { ICreateOneTimeTrigger } from "store/interfaces/customers";
import { useParams } from "react-router-dom";
import RightDrawer from "shared/ui/Drawer";
import { addMinutes, isBefore } from "date-fns";
import useTranslation from "shared/helpers/hooks/useTranslation";
import {
  DistributionSchedule,
  GetCampaignById,
  selectCampaignInfo,
} from "store/slicers/campaignDetail";
import { useSelector } from "react-redux";
import SharedDialog from "shared/ui/Dialog";

const defaultValues = {
  data: "",
  scheduleTime: "",
  scheduleDateTime: "",
  ignoreQuarantine: false,
};

const getTimeZonedDate = (date) => {
  const tzoffset = new Date().getTimezoneOffset() * 60000;
  const localISOTime = new Date(date - tzoffset).toISOString().slice(0, -1);
  return localISOTime;
};

const OneTimeSurveyForm = () => {
  const { surveyID } = useParams();
  const [isRescheduleOpen, setRescheduleOpen] = useState(false);
  const dispatch = useAsyncDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingNow, setLoadingNow] = useState(false);
  const [phoneDialogOpen, handleSetPhoneDialogOpen] = useState(false);
  const [invalidPhoneNumbers, setInvalidPhoneNumbers] = useState([]);
  const fileUpload = useRef();
  const campaignInfo = useSelector(selectCampaignInfo);
  const [activeStep, setActiveStep] = useState(0);
  const [oneTimeData, setOneTimeData] = useState(null);
  const t = useTranslation();

  const methods = useForm({
    defaultValues,
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    methods.reset({
      ignoreQuarantine: campaignInfo.ignoreQuarantine,
      scheduleTime: addMinutes(new Date(), 1),
      data: methods.watch("data"),
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onSubmit = async (formData) => {
    const splitted = formData.data.split("\n");

    const data = splitted.map((item) => {
      const spl = item.split(",");

      return {
        phoneNumber: spl[0]?.trim() || "",
        customerName: spl[1]?.trim() || "",
        customerSurname: spl[2]?.trim() || "",
        serviceType: spl[3]?.trim() || "",
      };
    });

    const scheduleTime = getTimeZonedDate(formData.scheduleTime);

    const requestData: ICreateOneTimeTrigger = {
      campaignID: surveyID,
      data,
      scheduleTime,
    };

    const { meta } = await dispatch(CreateOneTimeTrigger(requestData));

    if (meta.requestStatus !== ERequestStatus.FULFILLED) {
      return;
    }

    if (formData.ignoreQuarantine !== campaignInfo.ignoreQuarantine) {
      const data = {
        ignoreQuarantine: formData.ignoreQuarantine,
        triggerIDs: [],
        message: campaignInfo.message,
        numberOfTransaction: campaignInfo.numberOfTransaction,
        postpone: campaignInfo.postpone,
        postponeTime: campaignInfo.postponeTime,
        quarantinePeriod: campaignInfo.quarantinePeriod,
        surveyQuarantine: campaignInfo.surveyQuarantine,
        isLink: campaignInfo.isLink,
      };

      await dispatch(DistributionSchedule({ id: Number(surveyID), data }));
    }
  };

  const onPressCsv = () => {
    fileUpload?.current?.click();
  };

  const handleChangeCSV = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);

        let string = "";

        json.forEach((item, index) => {
          string = string.concat(
            `${item.phone}, ${item.name}, ${item.surname}, ${item.serviceType},`
          );

          if (index !== json.length - 1) {
            string = string.concat("\n");
          }
        });

        methods.reset({
          data: string,
        });
      };
      if (e.target.files?.[0]) {
        reader.readAsArrayBuffer(e.target.files[0]);
      }
    }
  };

  const onSendNow = async () => {
    setLoadingNow(true);
    methods.setValue("scheduleTime", addMinutes(new Date(), 1));
    await methods.handleSubmit(onSubmit)();
    toast.success("Survey has been sent");
    fetchOneTimeData();
    setLoadingNow(false);
  };

  const onSendOnDateTime = async () => {
    setLoading(true);
    await methods.handleSubmit(onSubmit)();
    toast.success("Survey scheduled successfully");
    fetchOneTimeData();
    setLoading(false);
  };

  const onCancel = async () => {
    const { meta } = await dispatch(CancelOneTimeTrigger(surveyID));

    if (meta.requestStatus !== ERequestStatus.FULFILLED) {
      return;
    }

    fetchOneTimeData();
    toast.success("One Time survey canceled successfully");
  };

  const onReschedule = async () => {
    const time = methods.watch("scheduleDateTime");

    if (isBefore(new Date(time), Date.now())) {
      return;
    }

    const scheduleDateTime = getTimeZonedDate(
      methods.watch("scheduleDateTime")
    );

    const requestData = {
      campaignId: surveyID,
      scheduleDateTime,
    };

    const { meta } = await dispatch(RescheduleOneTimeTrigger(requestData));
    if (meta.requestStatus !== ERequestStatus.FULFILLED) {
      return;
    }
    fetchOneTimeData();
    toast.success("One-time survey rescheduled successfully");
    setRescheduleOpen(false);
  };

  const fetchOneTimeData = useCallback(async () => {
    const { meta, payload } = await dispatch(GetOneTimeSurvey(surveyID));

    if (meta.requestStatus !== ERequestStatus.FULFILLED) {
      return;
    }

    setOneTimeData(payload);

    if (payload.count) {
      setActiveStep(-1);
    } else {
      setActiveStep(0);
    }

    if (payload.recipients?.length) {
      setInvalidPhoneNumbers(payload.recipients);
      handleSetPhoneDialogOpen(true);
    } else {
      setInvalidPhoneNumbers([]);
    }

    setTimeout(() => {
      methods.reset(defaultValues);
    }, 0);
  }, [dispatch, methods, surveyID]);

  const handleCopyNumbers = () => {
    navigator.clipboard.writeText(invalidPhoneNumbers.join(","));
    toast.success("This numbers are copied to clipboard");
  };

  const init = async () => {
    fetchOneTimeData();
    dispatch(GetCampaignById(Number(surveyID)));
  };

  useEffect(() => {
    init();
  }, [fetchOneTimeData]);

  return (
    <FormProvider {...methods}>
      <Box mt={2}>
        <Typography textAlign="center" variant="h5">
          Send a one-time survey
        </Typography>
        {oneTimeData?.count > 0 && (
          <Box>
            <Box
              mt={0.5}
              mb={1}
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
            >
              <InfoOutlined fontSize={"small"} color="action" />
              <Typography> You have scheduled one-time survey</Typography>
            </Box>
            <Box
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
              gap={2}
              mt={2}
            >
              <ButtonLoader
                isLoading={false}
                onClick={onCancel}
                variant="outlined"
              >
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  <CancelOutlined fontSize={"small"} />
                  <Typography>Cancel</Typography>
                </Box>
              </ButtonLoader>
              <ButtonLoader
                isLoading={false}
                variant="outlined"
                onClick={() => {
                  methods.setValue(
                    "scheduleDateTime",
                    new Date(oneTimeData.date)
                  );
                  setRescheduleOpen(true);
                }}
              >
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  <RefreshOutlined fontSize={"small"} />
                  <Typography>Reschedule</Typography>
                </Box>
              </ButtonLoader>
            </Box>
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent={"center"}>
        <Box component={Paper} p={2} mx={2} mt={2} width="90%">
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step>
              <StepLabel>Fill Recipient's information</StepLabel>
              <StepContent>
                <Box>
                  <Box mb={2}>
                    <Typography>
                      Paste user data below (one per line)
                    </Typography>
                  </Box>
                  <Box flex={1} mb={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Controller
                          name="data"
                          control={methods.control}
                          render={({ field }) => (
                            <TextArea
                              {...field}
                              style={{ minHeight: "200px" }}
                              placeholder="37400000000, John Smith, john@gmail.com"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    <ButtonLoader
                      fullWidth
                      isLoading={false}
                      variant="outlined"
                      onClick={onPressCsv}
                    >
                      <Box>
                        <input
                          type="file"
                          ref={fileUpload}
                          id="upload"
                          accept=".xlsx, .csv"
                          style={{ display: "none" }}
                          onChange={handleChangeCSV}
                        />
                        <UploadFile />
                        <Typography>Upload CSV / XLSX</Typography>
                      </Box>
                    </ButtonLoader>
                  </Box>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      disabled={!methods.watch("data")}
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Continue
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Schedule delivery for</StepLabel>
              <StepContent>
                <Box display="flex" gap={2}>
                  <BasicDateTimePicker
                    defaultValue={addMinutes(new Date(), 1)}
                    sx={{ width: "100%" }}
                    name={"scheduleTime"}
                    disablePast
                    rules={{
                      required: {
                        value: true,
                        message: "Required",
                      },
                    }}
                    label={"Date and Time"}
                  />
                </Box>
                <Box flex={1}>
                  <Box
                    display="flex"
                    justifyContent={"space-between"}
                    alignItems={"stretch"}
                    mb={2}
                    mt={2}
                  >
                    <Box display="flex" alignItems="center">
                      <Typography>
                        {t("include_the_quarantined_customers")}
                      </Typography>
                    </Box>
                    <Box flex={1}>
                      <Controller
                        name="ignoreQuarantine"
                        control={methods.control}
                        render={({ field }) => (
                          <Tooltip
                            placement="top-start"
                            title={
                              "In case activation of this function the survey will be sent the quarantined customers as well after receiving the trigger."
                            }
                          >
                            <Switch
                              color="success"
                              {...field}
                              checked={field.value}
                            />
                          </Tooltip>
                        )}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{ mb: 2 }}
                  display="flex"
                  gap={2}
                  alignItems={"center"}
                >
                  <ButtonLoader
                    isLoading={loading}
                    variant="contained"
                    onClick={onSendOnDateTime}
                  >
                    <Typography> Schedule sending</Typography>
                  </ButtonLoader>

                  <ButtonLoader
                    isLoading={loadingNow}
                    variant="outlined"
                    onClick={onSendNow}
                  >
                    <Typography> Send now</Typography>
                  </ButtonLoader>

                  <Button onClick={handleBack}>Back</Button>
                </Box>
              </StepContent>
            </Step>
          </Stepper>
        </Box>
      </Box>
      <RightDrawer
        open={isRescheduleOpen}
        setOpen={() => setRescheduleOpen(false)}
        title={"Reschedule One-time survey"}
      >
        <Box display="flex" gap={2}>
          <BasicDateTimePicker
            defaultValue={addMinutes(new Date(), 1)}
            sx={{ width: "100%" }}
            disablePast
            name={"scheduleDateTime"}
            rules={{
              required: {
                value: true,
                message: "Required",
              },
            }}
            label={"Date and Time"}
          />
        </Box>
        <Button
          variant="contained"
          onClick={onReschedule}
          sx={{ mt: 1, mr: 1 }}
        >
          Submit
        </Button>
      </RightDrawer>
      <SharedDialog
        open={phoneDialogOpen}
        setOpen={handleSetPhoneDialogOpen}
        onSuccess={handleCopyNumbers}
        successText="Copy"
        cancelText="Close"
        textConfig={{
          title: "Warning",
        }}
      >
        <Typography>
          This phone numbers will not receive one time survey
        </Typography>
        {invalidPhoneNumbers.map((num, index) => {
          return <Typography key={index}>{num}</Typography>;
        })}
      </SharedDialog>
    </FormProvider>
  );
};

export default OneTimeSurveyForm;
