import { Grid, Card, CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAsyncDispatch } from "shared/helpers/hooks/useAsyncDispatch";
import { requiredRules } from "shared/helpers/validators";
import ButtonLoader from "shared/ui/ButtonLoader";
import BasicSelect from "shared/ui/Select";
import TextInput from "shared/ui/TextInput";
import { ERequestStatus } from "store/enums/index.enum";
import { IUser, IViewUserRoleItem } from "store/interfaces/users";
import {
  GetUserById,
  UpdateUser,
  selectUserViewRolesList,
} from "store/slicers/users";

interface IFormData {
  roleID: number | string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  title: string;
  titleName: string;
  department: string;
  departmentName: string;
  personalNumber: string;
  parentID: string;
  group: number;
}

enum ERoles {
  SuperAdmin = 1,
}

const UserUpdate = ({
  userId,
  onClose,
}: {
  userId: number;
  onClose: () => void;
}) => {
  const dispatch = useAsyncDispatch();
  const userRolesList = useSelector(selectUserViewRolesList);

  const methods = useForm<IFormData>();

  const onSubmit = async (data: IFormData) => {
    // group :1 is Standard User
    const { meta } = await dispatch(
      UpdateUser({ formData: { ...data, group: 1 }, id: userId })
    );

    if (meta.requestStatus !== ERequestStatus.FULFILLED) {
      return;
    }

    toast.success("User successfully updated");
    onClose?.();
  };

  const isOptionDisabled = useCallback((option) => {
    return option.id === ERoles.SuperAdmin;
  }, []);

  const handleFetchUserDetailedInfo = useCallback(async () => {
    const { meta, payload } = await dispatch(GetUserById(userId));

    const typedPayload = payload as IUser;
    if (meta.requestStatus !== ERequestStatus.FULFILLED) {
      return;
    }

    methods.reset({
      name: typedPayload?.name,
      surname: typedPayload?.surname,
      email: typedPayload?.email,
      phone: typedPayload?.phone,
      title: typedPayload.position,
      titleName: typedPayload.positionName,
      department: typedPayload?.department,
      departmentName: typedPayload?.departmentName,
      personalNumber: typedPayload?.personalNumber,
      parentID: typedPayload?.ManagerUser?.personalNumber,
      roleID: typedPayload?.role?.id,
      group: typedPayload?.userGroup,
    });
  }, [dispatch, userId, methods]);

  useEffect(() => {
    handleFetchUserDetailedInfo();
  }, [handleFetchUserDetailedInfo]);

  return (
    <Box>
      <FormProvider {...methods}>
        <Grid item xs={12} md={6} lg={8}>
          <Card>
            <CardContent sx={{ pt: 4 }}>
              <Box
                mt={4}
                sx={{
                  display: "grid",
                  gap: 2,
                  gridTemplateColumns: "repeat(2,1fr)",
                }}
              >
                <TextInput<IFormData>
                  label="Name"
                  name="name"
                  rules={requiredRules}
                />
                <TextInput<IFormData>
                  label="Surname"
                  name="surname"
                  rules={requiredRules}
                />
                <TextInput<IFormData>
                  label="Email"
                  name="email"
                  rules={requiredRules}
                />
                <TextInput<IFormData>
                  label="Phone"
                  name="phone"
                  type="number"
                />
                <TextInput<IFormData>
                  label="Department"
                  name="department"
                  rules={requiredRules}
                />
                <TextInput<IFormData>
                  label="departmentName"
                  name="departmentName"
                />
                <TextInput<IFormData>
                  label="title"
                  name="title"
                  rules={requiredRules}
                />
                <TextInput<IFormData> label="titleName" name="titleName" />
                <TextInput<IFormData>
                  label="Personal Number"
                  name="personalNumber"
                  rules={requiredRules}
                />
                <TextInput<IFormData>
                  label="parentPersonalNumber"
                  name="parentID"
                />
                <BasicSelect<IViewUserRoleItem>
                  name="roleID"
                  label="Role"
                  valueProp="id"
                  labelProp="label"
                  defaultValue=""
                  isOptionDisabled={isOptionDisabled}
                  options={userRolesList}
                  hasDisabledOption
                />
              </Box>
              <Box mt={4}>
                <ButtonLoader
                  fullWidth
                  onClick={methods.handleSubmit(onSubmit)}
                  isLoading={false}
                  type="submit"
                  disabled={
                    !Object.values(methods?.formState?.dirtyFields)?.length
                  }
                >
                  <Typography>{"Save"}</Typography>
                </ButtonLoader>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </FormProvider>
    </Box>
  );
};

export default UserUpdate;
