export enum EMultipleConfigType {
  UNLIMITED = 1,
  EXACT,
  RANGE,
}

export enum ECommentConfigType {
  UNLIMITED = 1,
  RANGE,
}
